<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col lg="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">สินค้าไฮไลท์</h1>
        </b-col>
        <b-col lg="8" class="text-right">
          <div class="d-flex justify-content-end w-100">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="ชื่อสินค้า, SKU"
                v-model="filter.Search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
            <b-button class="btn-main" @click="openModalProduct()"
              >เพิ่มสินค้า</b-button
            >
          </div>
        </b-col>
      </CRow>

      <div class="mt-4 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(imageUrl)="data">
                <div class="position-relative">
                  <div
                    class="square-box b-contain"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                  ></div>
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(price)="data">
                <!-- <p class="m-0 line-through text-muted">
                  ฿ {{ data.item.price | numeral("0,0.00") }}
                </p> -->
                <p class="mt-1">฿ {{ data.item.price | numeral("0,0.00") }}</p>
              </template>
              <template v-slot:cell(action)="data">
                <b-button
                  class="btn btn-danger btn-details-set mr-md-2"
                  :disabled="isDisable"
                  @click="openModalDelete(data.item)"
                  >ลบสินค้า</b-button
                >
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Modal -->
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />

    <b-modal
      ref="ModalProduct"
      v-model="showModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <CRow class="no-gutters px-3 px-sm-0 mt-2">
        <b-col lg="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">เพิ่มสินค้า</h1>
        </b-col>
        <b-col lg="8" class="text-right">
          <div class="d-block d-sm-flex">
            <div class="d-flex justify-content-end w-100">
              <b-input-group class="panel-input-serach search-modal">
                <b-form-input
                  class="input-serach"
                  placeholder="ชื่อสินค้า, SKU"
                  v-model="filterProduct.Search"
                  @keyup="handleSearch(1)"
                ></b-form-input>
                <b-input-group-prepend @click="btnSearch">
                  <span class="icon-input m-auto pr-2">
                    <font-awesome-icon icon="search" title="View" />
                  </span>
                </b-input-group-prepend>
              </b-input-group>
            </div>
          </div>
        </b-col>
      </CRow>
      <div class="mt-4 bg-white px-0">
        <b-row class="no-gutters table-detail">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fieldsProduct"
              :items="itemsProduct"
              :busy="isBusyProduct"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :value="data.item.id"
                  v-model="selected"
                  v-on:change="
                    handleItem($event, data.item.id, data.item.price)
                  "
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(imageUrl)="data">
                <div class="position-relative">
                  <div
                    class="square-boxs b-contain"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                  ></div>
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(sku)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.sku }}</p>
              </template>
              <template v-slot:cell(price)="data">
                <p class="mb-0 nobreak two-lines">
                  ฿ {{ data.item.price | numeral("0,0.00") }}
                </p>
              </template>
              <!-- <template v-slot:cell(action)="data">
                <b-form-input
                  class="input-serach"
                  id="newPrice"
                  placeholder="กรอกราคา"
                  type="number"
                  v-model="
                    newPrice[
                      (filterProduct.PageNo - 1) * filterProduct.PerPage +
                        data.index
                    ]
                  "
                  @keyup="
                    handleItem(
                      $event,
                      data.item.id,
                      (filterProduct.PageNo - 1) * filterProduct.PerPage +
                        data.index
                    )
                  "
                ></b-form-input>
              </template> -->
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filterProduct.PageNo"
                :total-rows="rowsProduct"
                :per-page="filterProduct.PerPage"
                class="m-md-0"
                @change="paginationProduct"
                align="center"
              ></b-pagination>
            </div>
            <div class="d-sm-flex m-3 footer-btn">
              <b-button
                class="btn-main p-2 mx-2"
                v-if="this.newArray.length > 0"
                :disabled="isDisable"
                @click="save()"
                >บันทึก</b-button
              >
              <b-button class="btn-cancel btn-details-set p-2" @click="hide()"
                >ยกเลิก</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import CategoryHierarchy from "@/components/categoryHierarchy/CategoryHierarchy";

export default {
  name: "ProductHighlight",
  components: {
    InputSelect,
    ModalAlertConfirm,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    CategoryHierarchy,
  },
  data() {
    return {
      isLoading: true,
      show: false,
      showModal: false,
      isDisable: false,
      statusList: [],
      modalMessage: "",
      error: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        { key: "imageUrl", label: "ภาพประกอบ", class: "w-100px text-nowrap" },
        {
          key: "sku",
          label: "SKU",
          class: "w-100px text-nowrap",
        },
        {
          key: "name",
          label: "รายละเอียดสินค้า",
          class: "w-100px text-nowrap",
        },
        { key: "price", label: "ราคา", class: "w-100px text-nowrap" },
        { key: "action", label: "ทำรายการ", class: "text-center" },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        type: 1,
        Search: "",
      },
      fieldsProduct: [
        { key: "ids", label: "เลือก", class: "w-50px text-nowrap" },
        { key: "imageUrl", label: "ภาพประกอบ", class: "w-100px text-nowrap" },
        {
          key: "name",
          label: "รายละเอียดสินค้า",
          class: "w-100px text-nowrap",
        },
        {
          key: "sku",
          label: "รายละเอียดสินค้า",
          class: "w-100px text-nowrap",
        },
        { key: "price", label: "ราคา", class: "w-100px text-nowrap" },
        // { key: "action", label: "ราคา", class: "w-100px text-nowrap" },
      ],
      itemsProduct: [],
      isBusyProduct: false,
      rowsProduct: 0,
      filterProduct: {
        PageNo: 1,
        PerPage: 5,
        Search: "",
        Status: [],
        CategoryId: [],
        SortByOrderOrId: 2,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      selected: [],
      newPrice: [],
      newArray: [],
      totalRowMessage: "",
      timer: null,
    };
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    async getList(type) {
      this.isBusyProduct = true;
      let productList = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Product/HighLight/Add/List/${this.filter.type}`,
        null,
        this.$headers,
        this.filterProduct
      );

      if (productList.result == 1) {
        this.itemsProduct = productList.detail.dataList;
        this.rowsProduct = productList.detail.count;
        this.isBusyProduct = false;
      }
      if (type) return;

      this.isBusy = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Product/HighLight/page/${this.filter.PageNo}/perpage/${this.filter.PerPage}/${this.filter.type}`,
        {
          search: this.filter.Search,
        },
        this.$headers,
        null
      );

      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        this.isBusy = false;
      }
    },
    handleItem(e, id, price) {
      if (e) {
        // let findId = this.selected.findIndex((e) => {
        //   return e === id;
        // });
        // if (findId === -1) {
        //   this.selected.push(id);
        // }

        let find = this.newArray.findIndex((e) => {
          return e.productId === id;
        });
        let priceAfterDiscount = parseFloat(price);

        if (find === -1) {
          this.newArray.push({
            productId: id,
            priceAfterDiscount: priceAfterDiscount,
          });
        } else {
          this.newArray[find].priceAfterDiscount = priceAfterDiscount;
        }
      } else {
        let find = this.newArray.findIndex((e) => {
          return e.productId === id;
        });

        if (find !== -1) {
          this.newArray.splice(find, 1);
        }
      }
    },
    async save() {
      if (this.newArray.length > 0) {
        this.isDisable = true;
        this.$refs.modalLoading.show();

        let data = await this.$callApi(
          "post",
          `${this.$baseUrl}/api/Product/HighLight/add`,
          null,
          this.$headers,
          {
            product: this.newArray,
            type: this.filter.type,
          }
        );

        this.modalMessage = data.message;
        this.isDisable = false;
        this.$refs.modalLoading.hide();
        this.resetProduct();
        this.hide();

        if (data.result == 1) {
          this.modalMessage = "สำเร็จ";
          this.$refs.modalAlert.show();

          setTimeout(() => {
            this.$refs.modalAlert.hide();
          }, 1200);
          this.getList();
        } else {
          this.$refs.modalAlertError.show();
        }
      }
    },
    hide() {
      this.showModal = false;
    },
    async pagination(Page) {
      this.filter.PageNo = Page;
      await this.getList();
      window.scrollTo(0, 0);
    },
    paginationProduct(Page) {
      this.filterProduct.PageNo = Page;
      this.getList(true);
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    handleSearch(type) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (type === 1) {
          this.filterProduct.PageNo = 1;
          this.getList(true);
        } else {
          this.filter.PageNo = 1;
          this.getList();
        }
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },
    resetProduct() {
      this.filterProduct = {
        PageNo: 1,
        PerPage: 5,
        Search: "",
        Status: [],
        CategoryId: [],
        SortByOrderOrId: 2,
      };
      this.newArray = [];
      this.newPrice = [];
      this.selected = [];
    },
    openModalProduct() {
      this.resetProduct();
      this.getList(true);
      this.$refs.ModalProduct.show();
    },
    openModalDelete(value) {
      this.requestDeleteUser.userId = value.id;
      this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
    },
    async btnDelete() {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/Product/HighLight/remove/${this.filter.type}/${this.requestDeleteUser.userId}`,
        null,
        this.$headers,
        null
      );

      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 1200);

        this.filter.Page = 1;
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.menuactive {
  color: #ffb300 !important;
}

.line-through {
  text-decoration: line-through;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

::v-deep .square-box {
  padding-top: 5rem !important;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 820px) {
  ::v-deep .modal-body .footer-paginate {
    justify-content: center !important;
  }
}

@media (max-width: 430px) {
  ::v-deep .modal-body .footer-btn {
    margin: 0rem !important;
    padding: 1rem 0rem !important;
  }

  ::v-deep .btn-details-set {
    width: 8rem !important;
    margin: 0 !important;
  }
}
</style>
